import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: _ctx.width,
    centered: "",
    visible: _ctx.visible,
    footer: null,
    closable: false
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.visibleFooter)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
            _createVNode(_component_a_button, {
              class: "px-5",
              type: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeAlertModal')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("OK")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["width", "visible"]))
}