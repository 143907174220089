
import { defineComponent, reactive, ref, UnwrapRef, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { RuleObject } from "ant-design-vue/es/form/interface";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import "ant-design-vue/es/modal/style";
import { useAuth } from "@/_modules/auth";
import IconSVG from "@/components/IconSVG.vue";
import AlertModal from "@/components/AlertModal.vue";
interface IVisibleModal {
  signIn: boolean;
  signUp: boolean;
  alert: boolean;
  signOut: boolean;
}
interface SignInFormState {
  email: string;
  password: string;
}
interface SignUpFormState extends SignInFormState {
  confirmPassword: string;
}
export default defineComponent({
  components: {
    IconSVG,
    AlertModal,
    InfoCircleOutlined,
    // DownOutlined
  },
  setup() {
    const router = useRouter();
    const { token, account, logout } = useAuth();
    const ableToManageMember = ref<boolean>(false);
    watchEffect(() => {
      const accountRoles = account.value?.account_roles ?? null;
      if (accountRoles) {
        ableToManageMember.value = accountRoles.some(
          (ele) =>
            ele.roles_permission.permissions.permission_menu
              .application_management_edit === true
        );
      }
    });
    const signInFormRef = ref();
    const signUpFormRef = ref();
    const signObj = {
      email: "",
      password: "",
    };
    const signInFormState: UnwrapRef<SignInFormState> = reactive(signObj);
    const signUpFormState: UnwrapRef<SignUpFormState> = reactive({
      ...signObj,
      confirmPassword: "",
    });
    const visibleModal = reactive<IVisibleModal>({
      signIn: false,
      signUp: false,
      alert: false,
      signOut: false,
    });
    const alertMessage = ref<string>("");
    const signInRules = {
      email: [
        {
          required: true,
          message: "Please input your e-mail address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input an e-mail address format",
          trigger: "blur",
        },
        //{ min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
      ],
      password: [
        {
          required: true,
          message: "Please input your password",
          trigger: "blur",
        },
      ],
    };
    let validatePassword = async (rule: RuleObject, value: string) => {
      if (value === "") {
        return await Promise.reject("Please input the password");
      } else {
        if (signUpFormState.password !== "") {
          signUpFormRef.value.validateField("confirmPassword");
        }
        return await Promise.resolve();
      }
    };
    let validateConfirmPassword = async (rule: RuleObject, value: string) => {
      if (value === "") {
        return await Promise.reject("Please input the password again");
      } else if (value !== signUpFormState.password) {
        return await Promise.reject("Two inputs don't match!");
      } else {
        return await Promise.resolve();
      }
    };
    const signUpRules = {
      ...signInRules,
      password: [{ validator: validatePassword, trigger: "change" }],
      confirmPassword: [
        { validator: validateConfirmPassword, trigger: "change" },
      ],
    };
    const closeSignInModal = () => {
      visibleModal.signIn = false;
      signInFormRef.value.resetFields();
    };
    const closeSignUpModal = () => {
      visibleModal.signUp = false;
      signUpFormRef.value.resetFields();
    };
    const handleSignInFinish = (values: SignInFormState) => {
      alertMessage.value = "Coding is my job!";
      // visibleModal.alert = true
      // const { setUser } = useAuth()
      // setUser({
      //   email: values.email,
      //   id: 'test',
      //   [AUTH_TOKEN]: 'anyauthtoken'
      // }, true)
      closeSignInModal();
    };
    const openSignUpModal = () => {
      closeSignInModal();
      setTimeout(() => {
        visibleModal.signUp = true;
      }, 500);
    };
    const handleSignUpFinish = (values: SignUpFormState) => {
      closeSignUpModal();
    };

    const signOut = () => {
      logout();
      visibleModal.signOut = false;
      router.replace({ name: "login" });
    };

    return {
      visibleModal,
      signInFormState,
      signInRules,
      signInFormRef,
      closeSignInModal,
      handleSignInFinish,
      openSignUpModal,
      closeSignUpModal,
      handleSignUpFinish,
      signUpFormState,
      signUpFormRef,
      signUpRules,
      alertMessage,
      token,
      signOut,
      ableToManageMember,
    };
  },
});
